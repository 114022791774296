.header {
    display: flex;
    justify-content: space-between;
}
.app-header {
    background-color: #282c34;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
}

.app-link {
    color: #fff;
    &__active {
        color: #61dafb;
    }
}

.header-navigation {
    padding-left: 0;
    display: flex;
    justify-content: flex-end;
    list-style: none;
    
    &__right {
        margin-left: auto;
        margin-right: 0;
    }
    li {
        margin-left: 10px;
    }
}


.dashboard-nav-bar {
    position: absolute;
    top: 100px;
    left: 0;
    background: #282c34;
    color: #fff;
    padding-left: 20px;
    min-width: 180px;
    height: calc( 100vh - 100px );

    ul {
        list-style: none;
        padding-left: 0;
        ul {
            padding-left: 20px;
        }
    }
}

