.form-wrapper {
    background: lightgrey;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    padding: 15px 15px 20px 15px;
    margin-top: 10vh;

    &__signup {
        max-width: 300px;
    }
    
}

.form {
    &__header {
        margin-top: 0;
        margin-bottom: 10px;
        text-align: center;
    }
    
    
}

.form-control {
    &.error {
        input {
            border-color: red;
            color: red;
        }
        
        label {
            color: red;
        }
    }
    
    label {
        display: block;
        margin-bottom: 10px;
        margin-top: 15px;
    }
    
    input {
        &[type=text],
        &[type=email],
        &[type=password],
        &[type=text] {
            width: 100%
        }
        
        &[type=submit] {
            margin-top: 20px;
            display: block;
            margin-left: auto;
        }
    }
}

