.table {
    border-spacing: 0;
    th {
        text-align: left;
    }
    
    td {
        vertical-align: top;
    }
    
    &__admin {
        width: 100%;
        background: #fff;
        border: 1px solid #c3c4c7;
        box-shadow: 0 1px 1px rgb(0 0 0 / 4%);
        th,
        td {
            padding: 8px 10px;
        }
        thead {
            th {
                border-bottom: 1px solid grey;
            }
            tr {
                background-color: #f6f7f7;
            }
            td {
                border-bottom: 1px solid #c3c4c7;
            }
        }
        
        tbody tr:nth-child(even) {
            background-color: #f6f7f7;
        }
    }
    
    
    tr:hover {
        .column-actions {
            opacity: 1;
        }
    }
    .column-actions {
        opacity: 0;
        transition: opacity .5s ease;
        margin-bottom: 10px;
        a {
            font-size: .8em;
            margin-right: 10px;
        }
    }
    
    .link {
        color: #2271b1;
        
        &__remove {
            color: #b32d2e;
        }
    }
}