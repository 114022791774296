.header {
  display: flex;
  justify-content: space-between;
}

.app-header {
  background-color: #282c34;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.app-link {
  color: #fff;
}
.app-link__active {
  color: #61dafb;
}

.header-navigation {
  padding-left: 0;
  display: flex;
  justify-content: flex-end;
  list-style: none;
}
.header-navigation__right {
  margin-left: auto;
  margin-right: 0;
}
.header-navigation li {
  margin-left: 10px;
}

.dashboard-nav-bar {
  position: absolute;
  top: 100px;
  left: 0;
  background: #282c34;
  color: #fff;
  padding-left: 20px;
  min-width: 180px;
  height: calc( 100vh - 100px );
}
.dashboard-nav-bar ul {
  list-style: none;
  padding-left: 0;
}
.dashboard-nav-bar ul ul {
  padding-left: 20px;
}

.container {
  max-width: 1110px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.form-wrapper {
  background: lightgrey;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  padding: 15px 15px 20px 15px;
  margin-top: 10vh;
}
.form-wrapper__signup {
  max-width: 300px;
}

.form__header {
  margin-top: 0;
  margin-bottom: 10px;
  text-align: center;
}

.form-control.error input {
  border-color: red;
  color: red;
}
.form-control.error label {
  color: red;
}
.form-control label {
  display: block;
  margin-bottom: 10px;
  margin-top: 15px;
}
.form-control input[type=text], .form-control input[type=email], .form-control input[type=password], .form-control input[type=text] {
  width: 100%;
}
.form-control input[type=submit] {
  margin-top: 20px;
  display: block;
  margin-left: auto;
}

.table {
  border-spacing: 0;
}
.table th {
  text-align: left;
}
.table td {
  vertical-align: top;
}
.table__admin {
  width: 100%;
  background: #fff;
  border: 1px solid #c3c4c7;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.04);
}
.table__admin th,
.table__admin td {
  padding: 8px 10px;
}
.table__admin thead th {
  border-bottom: 1px solid grey;
}
.table__admin thead tr {
  background-color: #f6f7f7;
}
.table__admin thead td {
  border-bottom: 1px solid #c3c4c7;
}
.table__admin tbody tr:nth-child(even) {
  background-color: #f6f7f7;
}
.table tr:hover .column-actions {
  opacity: 1;
}
.table .column-actions {
  opacity: 0;
  transition: opacity 0.5s ease;
  margin-bottom: 10px;
}
.table .column-actions a {
  font-size: 0.8em;
  margin-right: 10px;
}
.table .link {
  color: #2271b1;
}
.table .link__remove {
  color: #b32d2e;
}

body {
  font-size: 16px;
  font-family: Avenir;
}

a {
  text-decoration: none;
  color: #fff;
  transition: color 0.5s ease;
}
a:hover {
  color: grey;
}

input {
  min-height: 40px;
  border: 1px solid black;
  border-radius: 3px;
  background: #fff;
  outline: none;
  padding-left: 10px;
  box-sizing: border-box;
}
input:focus {
  outline: none;
}

.text-center {
  text-align: center;
}

.homepage {
  padding-top: 100px;
}
.homepage h1 {
  text-align: center;
}

