body {
    font-size: 16px;
    font-family: Avenir;
}

a {
    text-decoration: none;
    color: #fff;
    transition: color .5s ease;
    
    &:hover {
        color: grey;
    }
}

input {
    min-height: 40px;
    border: 1px solid black;
    border-radius: 3px;
    background: #fff;
    outline: none;
    padding-left: 10px;
    box-sizing: border-box;
    &:focus {
        outline: none;
    }
}